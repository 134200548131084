import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        
        {/* <h1 style={{fontSize: '2em'}}>AIdeHub</h1> */}
        <h1>AIdeHub</h1>
        <p>The hub for AI aides</p>
        
      </header>
      
      <main className="App-main">
        <a
          className="App-link"
          href="https://news.aidehub.com"
          /* rel="noopener noreferrer" */
          style={{ fontSize: '2.5em', margin: '20px 0' }}
        >
          AIdeHub News
        </a>
      </main>
      
      <footer className="App-footer">
        <p>Version 0.1 - Alpha UniversE</p>
        <p>&copy; AIdeHub 2023. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
