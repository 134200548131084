// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body,
#root {
  height: 100%;
  width: 100%;
  
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  width: 100%;
  
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header,
.App-footer {
  /* paddin: 10px; */
  /* height: auto; */
  background-color: #3a87fe;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: 1.2rem;
  color: white;

  line-height: 0;
  
}


.App-main {
  /* height: 200px; */
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
  
}

.App-link {
  /* height: auto; */
  
  display: flex;
  alignt-items: center;
  justify-content: center;
  
  /* font-size: 1.2rem; */
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,WAAW;;EAEX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,WAAW;;EAEX,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,kBAAkB;AACpB;;AAEA;;;;;;;;;GASG;;AAEH;;EAEE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mCAAmC;EACnC,iBAAiB;EACjB,YAAY;;EAEZ,cAAc;;AAEhB;;;AAGA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,YAAY;;AAEd;;AAEA;EACE,kBAAkB;;EAElB,aAAa;EACb,oBAAoB;EACpB,uBAAuB;;EAEvB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;;;;;;;GAOG","sourcesContent":["html, body,\n#root {\n  height: 100%;\n  width: 100%;\n  \n  margin: 0;\n  padding: 0;\n}\n\n.App {\n  height: 100%;\n  width: 100%;\n  \n  /* display: flex; */\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  \n  text-align: center;\n}\n\n/* .App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n} */\n\n.App-header,\n.App-footer {\n  /* paddin: 10px; */\n  /* height: auto; */\n  background-color: #3a87fe;\n  /* min-height: 100vh; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  /* font-size: calc(10px + 2vmin); */\n  font-size: 1.2rem;\n  color: white;\n\n  line-height: 0;\n  \n}\n\n\n.App-main {\n  /* height: 200px; */\n  height: 75%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  flex-grow: 1;\n  \n}\n\n.App-link {\n  /* height: auto; */\n  \n  display: flex;\n  alignt-items: center;\n  justify-content: center;\n  \n  /* font-size: 1.2rem; */\n  color: #61dafb;\n}\n\n/* @keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
